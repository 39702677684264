<template>
  <div id="setup-container">
    <h2><i class="fad fa-fw fa-box-full"></i> Erste Schritte</h2>
    <p class="lead">
      Los geht's.
    </p>

    <div class="row justify-content-center mb-4">
      <div class="col-sm-9 col-md-7 col-lg-5">
        <EditProgress :steps="editSteps" :step-index="step" @setStep="step = $event"></EditProgress>
      </div>
      <div class="w-100 my-4"></div>

      <div class="col-sm-10 col-md-8 col-lg-6">
        <div v-if="error">
          <h3 class="text-center">Sorry...</h3>
          <p class="text-center">
            Ein Fehler ist aufgetreten. Bitte versuche es später erneut, wir versuchen das Problem umgehend zu beheben.
          </p>
        </div>
        <div v-else-if="step === 0" >
          <h3 class="text-center">Vervollständige noch kurz ein paar Daten.</h3>
          <p class="text-center">
            Damit dein Team später weiß wer du bist.
          </p>

          <hr>

          <div class="row justify-content-center align-items-center mb-3">
            <div class="col-md-7">
              <div class="form-group">
                <label for="firstName">Vorname:</label>
                <input id="firstName"
                       type="text"
                       v-model="newUser.firstName"
                       class="form-control"
                       placeholder="Vorname"
                       autocomplete="given-name"
                       required
                >
              </div>
              <div class="form-group">
                <label for="lastName">Nachname:</label>
                <input id="lastName"
                       type="text"
                       v-model="newUser.lastName"
                       class="form-control"
                       placeholder="Nachname"
                       autocomplete="last-name"
                       required
                >
              </div>

              <div class="form-group">
                <label for="nickName">Nickname:</label>
                <input id="nickName"
                       type="text"
                       v-model="newUser.nickname"
                       class="form-control"
                       placeholder="Dein Nickname für den Chat"
                       autocomplete="nickname"
                       required
                >
              </div>
            </div>
            <div class="col-md-5 text-center">
              <div class="avatar-wrapper mb-2">
                <img v-if="newUser.logo" :src="newUser.logo" alt="Profilbild" class="img-fluid avatar">
                <div v-else class="avatar-placeholder">
                  <span>
                    <i class="fas fa-arrow-up"></i><br>
                    Profilbild hochladen
                  </span>
                </div>
              </div>
              <div v-if="newUser.logo" @click="newUser.logo = ''" class="btn btn-sm btn-dark remove-avatar-button mb-2">
                Profilbild entfernen <i class="fas fa-times"></i>
              </div>
              <FileUpload @filesSaved="filesSaved($event)" :multiple="false" :small="true"></FileUpload>
            </div>
          </div>

          <button class="btn btn-primary btn-block mt-3 mb-5" @click="updateUser">
            Weiter
          </button>
        </div>
        <div v-else-if="step === 1">
          <h3 class="text-center">Wähle das Projekt mit dem du starten möchtest.</h3>
          <p class="text-center">
            Keine Sorge, du kannst jederzeit zwischen den Projekten wechseln.
          </p>

          <hr>

          <div v-for="(project, index) in projectSelection" :key="index" class="card mb-3 clickable" @click="setProject(project._projectId)">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-2">
                  <div v-if="project.profile.logo" class="avatar-wrapper">
                    <img :src="project.profile.logo" alt="" class="img-fluid avatar">
                  </div>
                  <div v-else class="color-orb" :style="'background:' + project.settings.themeColor"></div>
                </div>
                <div class="col">
                  <h4>
                    {{ project.profile.projectName }}
                    <span class="text-muted small">({{ project.members.length }} Mitglied{{ project.members.length === 1 ? '' : 'er' }})</span>
                  </h4>
                  <p class="mb-0"><code>{{ project.profile.rootUrl }}</code></p>
                </div>
              </div>
            </div>
          </div>

          <p class="text-center text-muted small">oder:</p>

          <div class="card mb-3 clickable" @click="startProject">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col">
                  <h4 class="my-0 h5">
                    <i class="far fa-arrow-right"></i> Neues Projekt erstellen
                  </h4>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div v-else-if="step === 2">
          <h3 class="text-center">Erstelle deine Firma oder dein Projekt.</h3>
          <p class="text-center">
            Damit erhältst du zugeschnittene Informationen und Zugriff auf alle Funktionen der Toolbox.
          </p>

          <hr>

          <div class="form-group">
            <label for="projectName">Name des Projekts:</label>
            <input id="projectName"
                   type="text"
                   v-model="project.profile.projectName"
                   class="form-control"
                   placeholder="Name des projekts"
                   autocomplete="organization"
                   required
            >
          </div>

          <div class="form-group">
            <label for="branch">Branche deines Projekts:</label>
            <select id="branch"
                    v-model="project.profile.branch"
                    class="form-control"
                    name="branch"
            >
              <option value="">Branche wählen</option>
              <option v-for="(branch, index) in branches" :key="index" :value="branch">{{ branch }}</option>
            </select>
          </div>

          <div class="form-group">
            <label>Eure Theme-Farbe</label>
            <input v-model="project.settings.themeColor" class="form-control" placeholder="Themen-Farbe" type="color">
          </div>

          <div class="form-group">
            <label for="rootUrl">URL der Website des Projekts:</label>
            <input id="rootUrl"
                   type="text"
                   v-model="project.profile.rootUrl"
                   class="form-control"
                   placeholder="https://dein-projekt.de"
                   autocomplete="url"
                   required
            >
            <p class="small">
              Wenn du noch keine Domain hast, keine Sorge, du kannst sie später ergänzen.
            </p>
          </div>

          <div class="form-group">
            <label for="rootUrl">Weitere Team-Mates per E-Mail einladen:</label>
            <input v-model="inviteEmails" class="form-control mb-3" placeholder="Mehrere E-Mails mit Komma trennen" type="text">
            <p class="small text-muted">
              Aktuell werden noch keine Einladungs-Mails versandt. Bitte benachrichtige deine Team-Mitglieder manuell. <br>
              Du kannst in den Projekteinstellungen auch später noch neue Mitglieder einladen.
            </p>
          </div>

          <button class="btn btn-primary btn-block mt-3 mb-5" @click="updateProject">Jetzt loslegen</button>
        </div>
        <div v-else-if="step === 3">
          <div class="text-center mb-5">
            <h3 class="text-center">Scrape deine Website</h3>
            <p class="text-center">Hole das Maximum aus der Toolbox heraus.</p>
            <hr>
            <div class="form-group">
              <h5>Wie lautet die URL deiner Startseite?</h5>
              <p class="small mb-2">Beginnend mit <code>http://</code> oder <code>https://</code></p>
              <input type="text" v-model="approval.root_url" class="form-control">
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <h5>Sprache</h5>
                  <p class="small mb-2">
                    Wähle zwischen Deutsch und Englisch, um weitere Analysen auf deiner Seite durchführen zu lassen.
                  </p>
                  <select v-model="approval.language" class="form-control">
                    <option value="german" selected>Deutsch</option>
                    <option value="english">Englisch</option>
                    <option value="">Andere Sprache (Keine Analyse)</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <h5>HTML-Filter <span class="badge badge-info">PRO</span></h5>
                  <p class="small mb-2">
                    Wähle einen CSS-Selektor für den Scraper, um wiederkehrende Elemente wie die Navigation zu überspringen.
                  </p>
                  <input type="text" v-model="approval.content_selector" placeholder="z.B. div.content" class="form-control">
                </div>
              </div>
            </div>

            <div v-if="approval.size >= 500" class="row justify-content-center mt-4">
              <div class="col-sm-10 text-center">
                <i class="fad fa-exclamation-triangle fa-3x text-warning mb-3"></i>
                <h4>Achtung: Fortfahren auf eigene Gefahr</h4>
                <p>
                  Das Ergebnis deines Scrapes könnte sehr unübersichtlich werden. <br>
                  Es wird emfohlen deine Seitentypen beispielhaft einzeln zu scrapen um die App besser nutzen zu können.
                  <router-link to="/scraper/single">
                    <i class="fal fa-arrow-right"></i> Einzelne Seite scrapen
                  </router-link>
                </p>
                <p class="small text-muted">
                  Eine Funktion um Seiten ein- und auszuschließen kommt in einer der nächsten Versionen.
                </p>
              </div>
            </div>

            <button @click="prepareDownload" class="btn btn-primary btn-block mt-4 mb-3" :disabled="!isValidUrl">Weiter</button>
            <router-link to="/dashboard" class="btn btn-light btn-block mb-4">Überspringen</router-link>
          </div>
        </div>
        <div v-else-if="step === 4">
          <div class="text-center mb-5">
            <h5>Nun müssen wir nur noch sicherstellen, dass nur deine eigene Website gescraped wird.</h5>
            <p>
              Lade dazu einfach diese Datei auf die angegebene Adresse deines Servers hoch. <br>
              Du kannst den Scraper aber auch später laufen lassen, falls es dir gerade nicht passt.
            </p>
            <div class="my-4">
              <div @click="download($event)" class="btn btn-primary">
                <span v-if="!loading">Approval-Datei {{ fileDownloaded ? 'erneut ' : '' }}downloaden</span>
                <span v-else><i class="fad fa-circle-notch fa-spin"></i></span>
                <a id="approval-file-download" style="display:none"></a>
              </div>
              <p class="small mt-3">
                <strong>Hinweis:</strong> Ändere nicht den Dateinamen. Der Scraper sucht nach
                <code>{{ approval.root_url }}/scraping-approval.json</code>
              </p>
            </div>
          </div>

          <div v-if="!fileDownloaded" class="text-center mb-5">
            <router-link to="/dashboard" class="btn btn-light mb-5">Nicht jetzt</router-link>
          </div>
        </div>

        <div v-if="fileDownloaded">
          <div class="alert alert-success">
            <h4 class="h5">Sobald alles erledigt ist, klicke auf den Button unten, um den Scraper zu starten.</h4>
            <p>
              <strong>Wichtig:</strong> Solltest du diese Seite neu aufgerufen haben, musst du deine Approval-Datei neu
              herunterladen und auf deinem Server überschreiben. Mit jedem Aufruf der Seite wird eine neue, einzigartige
              ID erzeugt, die den Scraping-Vorgang dieser App zuordnet.
            </p>
            <button class="btn btn-block btn-success" @click="runScraper">
              <span v-if="initiatingScraper"><i class="fad fa-circle-notch fa-spin"></i> Dies kann eine Weile dauern.</span>
              <span v-else>Scraper anfordern</span>
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

const memberTemplate = require('../../backend/templates/member.template');

import FileUpload from "@/components/FileUpload";
import EditProgress from "@/components/EditProgress";
export default {
  name: 'Setup',
  components: {EditProgress, FileUpload},
  data() {
    return {
      override: false,
      step: 0,
      error: false,
      editSteps: [
          "Profil erstellen",
          "Projekt wählen",
          "Dein Projekt",
          "Website einrichten",
          "Scraper starten"
      ],
      newUser: {},
      inviteEmails: "",
      loading: true,
      initiatingScraper: false,
      fileDownloaded: false,
      skipRun: false,
      skipApproval: false,
      approval: {
        id: "",
        date: "",
        root_url: "",
        content_selector: "",
        scrape_excludes: [],
        language: "german",
        size: 10,
        max_depth: 2,
        sleep: 2,
        status: "approved"
      },
      depthMapping: {
        1001: 1,
        501: 2,
        101: 3,
        11: 4,
      },
      waitingDots: "",
      redirectTarget: "/dashboard",
    }
  },
  computed: {
    projectSelection() {
      return this.project.projectSelection || [];
    },
    branches() {
      return this.$store.state.practiceFilters.industry;
    },
    nextScrape() {
      return this.nextApproval === 1 ? 'morgen' : ('in ' + this.nextApproval + ' Tagen');
    },
    size() {
      return this.approval.size;
    },
    isValidUrl() {
      return this.checkUrl(this.approval.root_url);
    },
    scraperUpdate() {
      let update = this.socket.scraperUpdate;
      return update.msg || "Warten auf Lebenszeichen";
    },
  },
  watch: {
    size(newVal) {
      for (let size of Object.keys(this.depthMapping)) {
        if (newVal < size) {
          this.approval.max_depth = this.depthMapping[size];
          break;
        }
      }
    },
    isAdmin(newVal) {
      if (newVal) {
        this.approval.id = "admin";
        this.approval.status = "admin";
      } else {
        this.approval.id = this.generateId();
        this.approval.status = "approved";
      }
    },
    scraperUpdate(newVal) {
      if (newVal === "Fehler") {
        this.$router.go();
      } else if (newVal === "Daten wurden übertragen") {
        this.$router.go();
      }
    }
  },
  methods: {
    filesSaved($event) {
      let files = JSON.parse(JSON.stringify($event));
      this.$store.dispatch('upload/uploadFiles', { files, path: "profile-img/" + this.user._id })
          .then(res => {
            console.debug(res);
            this.newUser.logo = res.files[0];
            this.$store.dispatch('user/updateUser', this.newUser);
          });
    },
    updateUser() {
      this.$store.dispatch('user/updateUser', this.newUser)
          .then(res => {
            console.debug('User updated', res);

            if (this.projectSelection.length) {
              this.step = 1;
            } else {
              this.createProject()
                  .then(() => {
                    this.step = 2;
                    this.approval.root_url = this.project.profile.rootUrl;
                  });
            }
          });
    },
    setProject(projectId) {
      this.$store.dispatch('project/getProjectById', projectId)
          .then(res => {
            console.debug('Project set', res);
            this.approval.root_url = res.project.profile.rootUrl;
            if (res.project.scraper.full.length) {
              this.$router.push(this.redirectTarget);
            } else if (res.project.profile.rootUrl) {
              this.step = 3;
            } else {
              this.step = 2;
            }
          });
    },
    startProject() {
      this.createProject()
        .then(() => {
          this.step = 2;
          this.approval.root_url = this.project.profile.rootUrl;
        });
    },
    createProject() {
      let user = JSON.parse(JSON.stringify(this.user));
      delete user._projectIds;

      let member = Object.assign(memberTemplate, user);
      member._userId = member._id;
      member.roles = ["creator", "admin"];
      member.memberSince = new Date().toISOString();
      member.settings.activeTheoryId = "";

      this.$store.commit('project/mergeProjectData', { members: [member] });
      this.project.profile.contactEmail = user.email;

      return new Promise(resolve => {
        this.$store.dispatch('project/createProject')
            .then(res => {
              console.debug("Project created", res);

              this.newUser.activeProjectId = res.project._id;
              this.approval.root_url = res.project.profile.rootUrl;
              this.$store.dispatch('user/updateUser', this.newUser)
                  .then(res => {
                    console.debug('User updated', res);
                    resolve();
                  })
                  .catch(err => {
                    console.warn(err);
                    this.error = true;
                  });
            });
      });
    },
    updateProject() {
      this.$store.commit('project/mergeProjectData', {profile: this.project.profile});

      let members = this.inviteEmails.split(",").map(e => {
        return { email: e.trim() }
      });
      members = this.project.members.concat(members);
      this.$store.commit('project/mergeProjectData', {members});

      this.$store.dispatch('project/updateProject')
          .then(res => {
            console.debug("Project updated", res);
            this.approval.root_url = res.project.profile.rootUrl;
            if (res.project.profile.rootUrl) {
              this.step = 3;
            } else {
              this.$router.push(this.redirectTarget);
            }
          });
    },
    cancelScrape() {
      this.project.scraper.status = ['cancelled'];
    },
    prepareDownload() {
      this.step++;
      setTimeout(() => {
        this.loading = false
      }, 500);
    },
    download($event) {
      $event.stopPropagation();
      this.loading = true;

      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.approval));
      let dlAnchorElem = document.getElementById('approval-file-download');
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", "scraping-approval.json");
      dlAnchorElem.click();

      console.info(this.approval);

      setTimeout(() => {
        this.loading = false;
        this.fileDownloaded = true;
      }, 1000);
    },
    runScraper() {
      this.initiatingScraper = true;
      if (this.isAdmin) {
        if (this.skipRun) {
          this.approval['skip_run'] = true;
        }
        if (this.skipApproval) {
          this.approval['skip_approval'] = true;
        }
      }
      this.$store.dispatch('project/initiateScraper', {type: "full", approval: this.approval})
          .then(() => {
            setTimeout(() => {
              this.initiatingScraper = false;
              this.$router.push("/scraper");
            }, 1000);
          });
    },
    retryScrape() {
      this.approval = this.project.scraper.full[this.project.scraper.full.length - 1];
      this.approval.date = new Date().toISOString();
      this.approval.status = "retry";
      if (confirm("Run approval:" + JSON.stringify(this.approval))) {
        this.project.scraper.status = ["idle"];
        this.runScraper();
      }
    },
    resetScraper() {
      this.project.scraper.status = ["idle"];
      this.$store.dispatch('project/resetScraper')
    }
  },
  beforeMount() {
    this.newUser = this.user;
    Object.assign(this.approval, this.project.scraper.full[this.project.scraper.full.length - 1]);
    this.approval.id = this.generateId();
    this.approval.date = new Date().toISOString();
    if (this.isAdmin) {
      this.override = true;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path.indexOf('/signup') >= 0) {
        vm.$store.commit('project/removeProjectData');
        vm.$store.commit('project/removeScrapeData');
      }
    });
  },
  mounted() {
    let count = 0;
    setInterval(() => {
      count++;
      this.waitingDots = "...".slice(0, count % 4);
    }, 1000);
  },
}
</script>

<style lang="scss" scoped>
.color-orb {
  border-radius: 100%;
  height: 0;
  width: 100%;
  padding-top: 100%;
}
.avatar-wrapper {
  width: 100%;
  padding-top: 100%;
  margin: 0;
}
.avatar-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--light);

  span {
    position: absolute;
    font-weight: 500;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}
.transparent {
  color: transparent;
}
</style>