<template>
  <div class="mb-4">
    <div class="row mb-2">
      <div v-for="(step, index) in steps"
           @click="clickStep(index)"
           class="col edit-step text-center"
           :class="(skippable ? 'skippable' : '') + (stepIndex === index ? ' active' : '')"
           :key="index"
      >
        <div class="step-indicator h5 mb-1">
          {{ index + 1 }}.
          <div class="indicator-bg"></div>
        </div>
        <div class="step-title">
          {{ step }}
        </div>
      </div>
    </div>
    <div class="progress">
      <div class="progress-bar"
           role="progressbar"
           aria-valuemin="0"
           aria-valuemax="100"
           :aria-valuenow="(stepIndex+1) / steps.length * 100"
           :style="'width:' + ((stepIndex+1) / steps.length * 100) + '%'"
      >
        {{ ((stepIndex+1) / steps.length * 100).toFixed() }} %
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditProgress',
  components: {},
  directives: {},
  props: {
    steps: Array,
    stepIndex: Number,
    skippable: Boolean
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    clickStep(index) {
      if (this.skippable) {
        this.$emit('setStep', index);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.edit-step {
  &.skippable {
    cursor: pointer;
  }

  &.active {
    color: var(--primary);
    .step-indicator .indicator-bg {
      background: var(--primary);
      opacity: .3;
    }
    .step-title {
      font-weight: 500;
    }
  }
  .step-indicator {
    position: relative;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    padding-top: .7rem;
    padding-left: .3rem;

    .indicator-bg {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: var(--dark);
      border-radius: 100%;
      height: 3rem;
      width: 3rem;
      opacity: .15;
    }
  }
}
</style>