let memberTemplate = {
  _userId: null,
  address: {
    additionalField: "",
    city: "",
    country: "",
    name: "",
    region: "",
    street: "",
    zipCode: "",
  },
  email: "",
  firstName: "",
  lastName: "",
  logo: "",
  memberSince: new Date(),
  roles: [],
  telephone: "",
  settings: {
    activeTheoryId: "",
    chatColor: ""
  }
}

module.exports = memberTemplate;