<template>
  <div>
    <form enctype="multipart/form-data" novalidate v-if="isInitial || isSaving">
      <div class="dropbox rounded" :class="small ? 'small' : ''">
        <input type="file"
               :multiple="multiple"
               :name="filename"
               :disabled="isSaving"
               @change="filesChange($event)"
               accept="image/*"
               class="input-file"
        >
        <p v-if="isInitial">
          Dateien hier her ziehen <br>
          oder klicken zum Auswählen.
        </p>
        <p v-if="isSaving">
          {{ fileCount }} Datei{{ fileCount === 1 ? '' : 'en' }} werden gelesen...
        </p>
      </div>
    </form>
    <!--SUCCESS-->
    <div class="card" v-if="isSuccess">
      <div class="card-body">
        <h5>{{ uploadedFiles.length }} Datei{{ fileCount === 1 ? '' : 'en' }} bereit für Upload.</h5>
        <p>
          <a href="javascript:void(0)" @click="reset()">Andere Datei auswählen</a>
        </p>
        <ul class="list-unstyled row">
          <li v-for="(item, index) in uploadedFiles" class="col-sm-6 col-md-4 col-lg-3" :key="index">
            <img :src="item.data" class="img-responsive img-thumbnail" :alt="item.originalName">
          </li>
        </ul>
      </div>
    </div>
    <!--FAILED-->
    <div v-if="isFailed">
      <h5>Lesen fehlgeschlagen.</h5>
      <p>
        <a href="javascript:void(0)" @click="reset()">Zurücksetzen</a>
      </p>
    </div>
    <div v-if="isTooLarge">
      <h5>Datei ist zu groß. Bitte verringere die Dateigröße.</h5>
      <p>
        <a href="javascript:void(0)" @click="reset()">Zurücksetzen</a>
      </p>
    </div>
  </div>
</template>

<script>
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

export default {
  name: 'FileUpload',
  props: {
    filename: String,
    small: Boolean,
    multiple: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      formData: [],
      fileCount: 0,
      isTooLarge: false
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }
  },
  methods: {
    reset() {
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    filesChange($event) {
      let fileList = $event.target.files;
      let formData = new FormData();

      if (!fileList.length) return;

      this.fileCount = fileList.length;

      for (let i = 0; i < fileList.length; i++) {
        formData.append('files', fileList[i], fileList[i].name);
      }

      this.save(formData);
    },
    save(formData) {
      this.currentStatus = STATUS_SAVING;

      this.upload(formData)
          .then(x => {
            this.uploadedFiles = [].concat(x);
            this.currentStatus = STATUS_SUCCESS;
            this.$emit('filesSaved', this.uploadedFiles)
          })
          .catch(err => {
            this.uploadError = err.response;
            this.currentStatus = STATUS_FAILED;
          });
    },
    upload(formData) {
      const files = formData.getAll('files');
      const promises = files.map((x) => this.getImage(x)
          .then(img => {
            return {
              id: this.generateId(),
              originalName: x.name,
              fileName: x.name,
              data: img,
            }
          }));
      return Promise.all(promises);
    },
    getImage(file) {
      return new Promise(resolve => {
        const reader = new FileReader();
        let img = document.createElement('img');

        reader.onload = () => {
          img.onload = () => {
            resolve(this.getBase64Image(img))
          }
          img.src = reader.result;
        };

        reader.readAsDataURL(file);
      })
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL('image/png');

      this.isTooLarge = dataURL.length > 31457280; // 30MB

      return this.isTooLarge || dataURL;
    }
  },
  mounted() {
    this.reset();
  },
}

</script>

<style lang="scss" scoped>
.dropbox {
  background: var(--light);
  color: var(--dark);
  position: relative;
  cursor: pointer;

  &:hover {
    background: var(--light);
  }

  p {
    font-size: 1.2em;
    text-align: center;
    padding: 2rem;
  }

  &.small p {
    padding: .5rem;
  }

  .input-file {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    cursor: pointer;
    opacity: 0;
  }
}
</style>